<template>
  <b-overlay :show="isLoading">
    <!-- <div class="googlelogin-cnt">Google Login Page Loading</div> -->

    <!-- Error page-->
    <div class="misc-wrapper">
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Vuexy</h2>
      </b-link>

      <div v-if="!responseStatus" class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">Authentication failed 🕵🏻‍♀️</h2>
          <p class="mb-2">Oops! 😖 The credentials does not match.</p>

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{ path: '/login' }"
          >
            Back to Login
          </b-button>

          <!-- image -->
          <b-img fluid :src="imgUrl" alt="Error page" />
        </div>
      </div>
      <div v-else class="googlelogin-cnt">Google Login Page Loading</div>
    </div>
    <!-- / Error page-->
  </b-overlay>
</template>

<script>
import { BOverlay, BLink, BButton, BImg } from "bootstrap-vue";
import axiosIns from "@/libs/axios";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

export default {
  components: {
    BOverlay,
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      isLoading: false,
      responseStatus: true,
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  mounted() {
    this.redirectToLogin();
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    redirectToLogin() {
      this.isLoading = true;
      //const token = 'ya29.a0ARrdaM-asim0uQMknbTyjXmn0-znxtA7Z4kPoco14e-KVN9uRYpR2hVTZwq53ptEEgFjy6w5HA1LwrdM8vl_cxZzVyeKYydHrCJ4UYot2s3QNN4bQbH8f7u97bHX1fKJ6A0GuCHlodLpa_wBIVCec6SqgCje'
     const token = this.$route.params.token;
     console.log('token---', this.$route.params.token);
      //console.log("signn in with google", access_token);
      // let token =
      // "ya29.a0ARrdaM9Ca7fUWfIM8CvztcD1XaSCkJ94w4KHJUj1VzSWgxC09NxlbHG47UfEp4jp9zYLHflVAjb-ViVUKdDyfpCn-380bbj9eQmp_1uMQnJO4OBgJPtERtzzCNt2NYH5KnHMvFhoBmJxRmc7Ow7XLFPJPulwFQ";
     // let token =
       // "ya29.a0ARrdaM_XzX7r6UnByfaCNbflkIDxVwOxdVsvAxRHrrfa2UMzjOo5sh-nqz29WNLz9OZRv6kZuOVIRTSMDkHQQUsjge67uljqOYu3NigePrU2-SviLwpLvo1JsJkNTo6NnjxbPk4rofbZ8r7CBR3lBqsq-Udu3w";
     
            store
            .dispatch('app-customer/GoogleLogin', token)
            .then((response) => {
 //console.log(response);
          this.isLoading = false;
          console.log('response google data', response);
          if (response.data.length < 1) {
            this.responseStatus = true;
            //console.log("response_google_login", response);
            this.$cookies.set("token", response.data[0].JWT_Token);
            this.$cookies.set("company_id", response.data[0].company_id);
            this.userAccounts = response.data[0].user_accounts;
            if (response.user_accounts != null) {
              console.log("multiple account here");
              this.isMultiAcountStatus = true;
            } else {
              if (response.data[0].profile_flag) {
                this.$router.push({ name: "update-profile" });
              } else {
                this.$router.push("/");
              }
            }
          } else {
          this.$router.push({ name: "login" });
          this.$cookies.set("token", this.$route.params.token);
     
 }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.responseStatus = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Invalid email or password",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
